import React from 'react'

const Footer = () => {
  return (
    <div className="w-full flex py-4">
      <p className="text-sm text-center w-full"><span className="opacity-50">Made with love by </span><span className="opacity-85 font-bold">Stella Raab</span><span className="opacity-50"> in {new Date().getFullYear()}</span></p>
    </div>
  )
}

export default Footer
