import React, { useState, useEffect } from 'react';

const EyesFollowMouse = () => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const eye = document.querySelector('.eye');

    const handleMouseMove = (event) => {
      const x = eye.offsetLeft + eye.offsetWidth / 2;
      const y = eye.offsetTop + eye.offsetHeight / 2;
      const rad = Math.atan2(event.pageX - x, event.pageY - y);
      const rot = (rad * (180 / Math.PI) * -1) + 180;

      setRotation(rot);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="eyescontainer flex justify-center">
      <div className="rounded-full portrait w-[311px] h-[311px] sm:w-[343px] sm:h-[343px]">
        <div className="eyes gap-3 ml-[38%] sm:ml-[39%] mt-[70px] sm:mt-[80px]">
        <div className="eye bg-gradient-to-t from-[#E7E9F1] to-white" style={{ transform: `rotate(${rotation}deg)` }}></div>
        <div className="eye bg-gradient-to-t from-[#E7E9F1] to-white" style={{ transform: `rotate(${rotation}deg)` }}></div>
        </div>
      </div>
    </div>
  );
};

export default EyesFollowMouse;
