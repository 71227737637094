import React, {useState} from 'react';
import {FaBars, FaTimes, FaGithub, FaLinkedinIn} from 'react-icons/fa';
import {BsDribbble} from 'react-icons/bs';
import {ReactComponent as Logo} from '../assets/logo.svg';
import {Link} from 'react-scroll'

const Navbar = () => {
    const [nav,setNav] = useState(false)
    const handleClick = () => setNav(!nav)
    const [navbar,setNavbar] = useState(false)

    const changeBackground = () => {
      if(window.scrollY >= 64) {
        setNavbar(true)
      } else {
        setNavbar(false)
      }
    }

    window.addEventListener('scroll', changeBackground);

  return (
    <div className={navbar ? 'navbar active fixed w-full h-[64px] flex justify-between items-center px-4' : 'navbar md:fixed w-full h-[64px] flex justify-between items-center px-4'}>
      {/* logo */}
      <div>
        <Logo className='w-16 dynamic-svg'  alt="Logo Stella Raab" />
      </div>

      {/* menu */}
      <ul className='hidden md:flex'>
        <li>
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li>
          <Link to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li>
          <Link to="freestyle" smooth={true} duration={500} offset={-80}>
            References
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* hamburger */}
      <div onClick={handleClick} className='md:hidden navicons'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}
      <ul className={!nav ? 'hidden' : 'mobinav top-0 left-0 w-full h-screen flex flex-col justify-center items-center'}>
        <li className='py-6 text-3xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-3xl'>
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-3xl'>
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='py-6 text-3xl'>
          <Link onClick={handleClick} to='freestyle' smooth={true} duration={500} offset={-72}>
            References
          </Link>
        </li>
        <li className='py-6 text-3xl'>
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Social icons */}
      <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
        <ul>
          <li className='socialicon w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-5px]'>
            <a href="https://dribbble.com/stellaraab" className='flex justify-between items-center w-full'>
              Dribbble <BsDribbble size={30}/>
            </a>
          </li>
          <li className='socialicon w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-5px]'>
            <a href="https://github.com/if-stella" className='flex justify-between items-center w-full'>
              Github <FaGithub size={30}/>
            </a>
          </li>
          <li className='socialicon w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-5px]'>
            <a href="https://de.linkedin.com/in/stella-raab" className='flex justify-between items-center w-full'>
              LinkedIn <FaLinkedinIn size={30}/>
            </a>
          </li>
        </ul>

      </div>

    </div>
  )
}

export default Navbar
