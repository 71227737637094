import React from 'react'

import {ReactComponent as Figma} from '../assets/skills-figma.svg';
import {ReactComponent as HTML} from '../assets/skills-html.svg';
import {ReactComponent as ReactImg} from '../assets/skills-react.svg';
import {ReactComponent as Tailwind} from '../assets/skills-tailwind.svg';
import {ReactComponent as Rails} from '../assets/skills-rails.svg';
import {ReactComponent as Jira} from '../assets/skills-jira.svg';
import {ReactComponent as SASS} from '../assets/skills-sass.svg';
import {ReactComponent as Javascript} from '../assets/skills-js.svg';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen'>
      {/* Container*/}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div>
          <h2 className='inline' data-aos="slide-up" data-aos-duration="300" data-aos-easing="ease-in-out">Skills</h2>
          <p className='py-4' data-aos="slide-up" data-aos-duration="350" data-aos-easing="ease-in-out">Some technologies I like to work with:</p>
        </div>
        <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center sm:py-8 py-2'>
          <div data-aos="slide-up" data-aos-duration="100" data-aos-easing="ease-in-out">
            <Figma className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="Figma icon"/>
            <h5 className='my-4'>Figma</h5>
          </div>
          <div data-aos="slide-up" data-aos-duration="200" data-aos-easing="ease-in-out">
            <HTML className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="HTML icon"/>
            <h5 className='my-4'>HTML</h5>
          </div>
          <div data-aos="slide-up" data-aos-duration="300" data-aos-easing="ease-in-out">
            <Javascript className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="JavaScript icon"/>
            <h5 className='my-4'>JavaScript</h5>
          </div>
          <div data-aos="slide-up" data-aos-duration="400" data-aos-easing="ease-in-out">
            <Jira className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="Github icon"/>
            <h5 className='my-4'>Jira</h5>
          </div>
          <div data-aos="slide-up" data-aos-duration="500" data-aos-easing="ease-in-out">
            <Rails className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="Rails icon"/>
            <h5 className='my-4'>Rails</h5>
          </div>
          <div data-aos="slide-up" data-aos-duration="600" data-aos-easing="ease-in-out">
            <ReactImg className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="React icon"/>
            <h5 className='my-4'>React</h5>
          </div>
          <div data-aos="slide-up" data-aos-duration="700" data-aos-easing="ease-in-out">
            <SASS className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="SASS icon"/>
            <h5 className='my-4'>Sass</h5>
          </div>
          <div data-aos="slide-up" data-aos-duration="800" data-aos-easing="ease-in-out">
            <Tailwind className='w-10 h-10 sm:w-14 sm:h-14 mx-auto dynamic-svg' alt="Tailwind icon"/>
            <h5 className='my-4'>Tailwind</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
