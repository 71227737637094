import React from 'react'
import {AiOutlineSend, AiOutlineArrowUp} from 'react-icons/ai'
import { Link } from 'react-scroll';

const Contact = () => {
  return (
    <div name='contact' className='relative w-full h-screen flex flex-col justify-center items-center p-4'>
      <form method='POST' action='https://getform.io/f/9815e8f6-0275-4b44-9579-f2ec012a3508' className='flex flex-col max-w-[600px] w-full pb-2'>
        <div>
          <h2 className='inline'>Contact</h2>
          <p className='py-4'>Submit the form below or contact me on my social links.</p>
        </div>
        <input className='my-4 p-2 inputfield rounded' type="text" placeholder='Name' name='name' />
        <input className='my-4 p-2 inputfield rounded' type="text" placeholder='Email' name='email' />
        <textarea className='my-4 p-2 inputfield rounded' name="message" rows="5" cols="30" placeholder='Your message'></textarea>
        <button className='primary group mx-auto'>Send me a message!
        <span className='group-hover:translate-x-1  duration-300'>
            <AiOutlineSend className='ml-2'/>
        </span>
        </button>
      </form>
      <Link to="home" smooth={true} duration={500}>
        <button className="scroll-top">
          <AiOutlineArrowUp />
        </button>
      </Link>
</div>
  )
}

export default Contact
