import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import Typewriter from 'typewriter-effect';
import {Link} from 'react-scroll'

export const Home = () => {
  return (
    <div name='home' className='w-full h-screen'>

      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <div style={{height: `32px`}} className="type-effect">
          <h5>
          <Typewriter
            loop={true}
            onInit={(typewriter) => {
            typewriter.typeString('<span style="font-size: 24px; line-height: 32px; font-family: Caveat"> <strong>Hey</strong>, my name is</span>')
            .pauseFor(300)
            .deleteChars(15)
            .typeString('<span style="font-size: 24px; line-height: 32px; font-family: Caveat"> <strong>Hallo</strong>, ich bin</span>')
            .pauseFor(300)
            .deleteChars(14)
            .typeString("<span style='font-size: 24px; line-height: 32px; font-family: Caveat'> <strong>Salut</strong>, je m'appelle</span>")
            .pauseFor(300)
            .deleteChars(19)
            .typeString('<span style="font-size: 24px; line-height: 32px; font-family: Caveat"> <strong>Hola</strong>, mi nombre es</span>')
            .pauseFor(1000)
            .start();
            }}
            options={{
              loop: true,
            }}
          />
          </h5>
        </div>
        <h1 className='sm:text-7xl py-1 gradient-headline'>Stella Raab</h1>
        <h3>UI-Designer & Frontend-Developer from Berlin.</h3>
        {/*<p className='py-5 max-w-[640px]'>I like to draw and eat a lot of things. Especially birds. I love going to the gym, to throw around heavy things, like myself.</p>*/}
        <div>
          <Link activeClass="active" to="freestyle" smooth={true} duration={500} offset={-140}>
            <button className="primary group mt-6">
              View work
              <span className='group-hover:rotate-90 duration-300'>
              <HiArrowNarrowRight className='ml-2'/>
              </span>
            </button>
          </Link>
        </div>
      </div>



    </div>
  )
}
